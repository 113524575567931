import {
  container,
  section,
  infoColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
  container,
  description: {
    color: infoColor[8],
    textAlign: "center"
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
       fontSize: "2.0em",
       fontWeight: "700",
       textShadow: "0 2px 3px rgba(" + hexToRgb(grayColor[9]) + ", 0.3) !important"
    }
  },
  features: {
    textAlign: "center !important",
    paddingTop: "30px",
    paddingBottom: "0px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em"
    }
  }
};

export default descriptionStyle;