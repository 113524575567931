import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import image6 from "assets/img/images/image6.jpg";

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';

import Button from "components/CustomButtons/Button.js";


//import whatsappImage from "assets/img/Whatsapp.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
  const classes = useStyles();
  return (
   /*  <div className={classes.section}> */
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={5}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Présentation de MultiBank Group</h3>
              <a href=" https://multibankfx.com/fr" target="_blank">
              <Button color="primary" justIcon square>
                <ArrowForwardIcon />
              </Button>
              </a>
              <hr></hr>
              <h3 className={classes.title}>
              Services Financiers MEX Atlantic (Partie intégrante de MultiBank Group)</h3>
              <a href="https://mexatlantic.com/about" target="_blank">
              <Button color="primary" justIcon square>
                <ArrowForwardIcon />
              </Button>
              </a>
             <hr></hr>
            {/*   <h3 className={classes.title}>Affiliation ouverture de compte</h3>
              <a href="https://mexatlantic.com/account/live-account?ibNum=6626625" target="_blank">
              <Button color="primary" justIcon square>
                <ArrowForwardIcon />
              </Button>
              </a>
          <hr></hr>   */}    
            </div>           
          </GridItem>
          {/* <GridItem md={7} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={image6}
                    alt=""
                    className={classes.infoImg}
                  />
                </ScrollAnimation>
              </div> 
              </div>
          </GridItem> */}
        </GridContainer>
      </div>
   /*  </div> */
  );
}
