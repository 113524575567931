import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

import sohoPicture from "assets/img/propositionPageSmall.jpg";
//import video from "assets/videos/inREALTIME_promotion_FR.mp4";
import ProMyTradeImage from "assets/img/images/Logo_ProMyTrade_1024.jpg";
import Candle from "assets/img/images/Candle.jpg";



import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Button from "components/CustomButtons/Button.js";

// import macbookImage from "assets/img/assets-for-demo/presentationViewSectionComponent/laptop-basics.png";
// import shoppingCartImage from "assets/img/assets-for-demo/presentationViewSectionComponent/table.jpg";
//import shareButtonImage from "assets/img/assets-for-demo/presentationViewSectionComponent/share-btn.jpg";
// import cardImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card-with-btn.jpg";
// import twitterImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card.jpg";
// import iconsImage from "assets/img/assets-for-demo/presentationViewSectionComponent/social-row.jpg";
// import repostImage from "assets/img/assets-for-demo/presentationViewSectionComponent/pin-btn.jpg";

const useStyles = makeStyles(componentsStyle);


export default function SectionComponents() {
  const classes = useStyles();
  return (
    // <div>
    <div className={classes.container}>
      <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <Button className={classes.shareButton}
            href="https://multibankfx.com/files/pdf/MEXAtlantic_PrivacyPolicy_14062023.pdf"
            color={"primary"}
            target="_blank"
            round
          >
           M.Légales
          </Button>
          <Button className={classes.shareButton2}
            href="https://multibankfx.com/files/pdf/AGR_MEXATL_AE_ClientAgreement_v10_15062023.pdf"
            color={"info"}
            target="_blank"
            round
          >
            T.Conditions
          </Button>
          <br></br><br></br>

          <h3 className={classes.title}>Votre Réussite est notre réussite...</h3>
          
          <h4 className={''}>
          <strong>ProMyTrade</strong> vous offre de nombreux avantages,<br></br> en ouvrant votre <strong>Compte GOLD</strong> avec le lien d’affiliation obtenez gratuitement la disponibilité de notre <strong>robot de trading automatique sur l’OR XAUUSD,</strong> celui-ci prend des ordres d’achat ou de vente en fonction de l‘évolution du marché, de plus vous n’avez aucunement besoin de rester devant votre ordinateur, le robot est autonome et fait les transactions à votre place.
          </h4>

          <h4 className={''}>
           <strong>Ou trader activement avec un Compte ECN</strong> à partir de 250 $ de dépot et des Spreads à partir de 0.0 pips.
          </h4>
          <h5 className={''}>
          <strong>Vous bénéficiez</strong>  aussi de conseils et d’un suivi lors de l’ouverture de votre compte.</h5>
        </GridItem>
        <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
          <div className={classes.imageContainer}>
            <img
              src={ProMyTradeImage}
              alt=""
              className={classes.componentsMacbook}
            />
          </div>
        </GridItem>
      </GridContainer>
      
      <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
     
     {/*  <Button
      href="https://mexatlantic.com/account/live-account?ibNum=6626625" target="_blank"
            color="warning"
            size="md"
            rel=""
          >
            Compte GOLD
          </Button> */}
          
          <img
            src={Candle}
            alt=""
            className={classes.cardImage}
          />
          </GridItem>
          </GridContainer>
      {/*     <Button
      href="https://mexatlantic.com/iframe/2716" target="_blank"
            color="success"
            size="md"
            rel=""
          >
            Compte ECN
          </Button> */}
          <br></br>
          <Button
      href="https://promytrade.com/ProMyTrade.pdf" target="_blank"
            color="danger"
            size="md"
            rel=""
          >
            Lire le PDF
          </Button>
    </div>
  );
}
