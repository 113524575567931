import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import examplesStyle from "assets/jss/material-kit-pro-react/views/presentationSections/examplesStyle.js";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import Info from "components/Typography/Info.js";

import combo1 from "assets/img/images/PresenceMondiale.jpg";
import combo2 from "assets/img/images/world.jpg";
//import combo3 from "assets/img/images/Candle.jpg";
import combo4 from "assets/img/images/image1.jpg";
import combo5 from "assets/img/images/image6.jpg";
import combo6 from "assets/img/images/image5.jpg";
import combo7 from "assets/img/images/image4.jpg";
import imageQR from "assets/img/images/imageQR.jpg";
import imagePartenariat from "assets/img/images/partenariat.jpg";
import ProMyTradeMexAtlantic from "assets/img/images/ProMyTradeMexAtlantic.jpg";


import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import Button from "components/CustomButtons/Button.js";



const styles = {
  ...examplesStyle,
  ...imagesStyles,
  ...projectsStyle
};

const useStyles = makeStyles(styles);

export default function SectionExamples() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h3 className={classes.title}>
              MultiBank Group possède plus de 25 bureaux dans le monde pour répondre à une clientèle internationale et collaborer avec des partenaires dans les principaux centres financiers du monde</h3>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.mlAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo1} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo1 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Introduction</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Présentation de MultiBank Group
                  <br></br>
                  Avantages et objectif du partenariat
                </h4>
                <p className={classes.description}>
                  <strong>Nous sommes ravis de vous présenter</strong> <Info><strong>une opportunité exceptionnelle</strong></Info> de partenariat avec MultiBank Group, un des leaders mondiaux des services financiers.<br></br>
                  En combinant notre expertise locale et notre engagement envers l'excellence avec les solutions innovantes et la portée mondiale de MultiBank Group, nous visons à offrir à nos clients des services financiers inégalés.<br></br>
                  <Info><strong>Ce partenariat stratégique</strong></Info> est conçu pour répondre aux besoins diversifiés de nos clients, en leur fournissant des outils et des ressources de pointe pour réussir dans un marché financier en constante évolution.<br></br>
                  <Info><strong>Nos valeurs fondamentales d'intégrité, de transparence et de service client</strong></Info> sont au cœur de cette collaboration, garantissant une expérience de qualité supérieure pour tous nos clients. <br></br>
                  <Info><strong>De plus, l'ouverture d'un compte chez MultiBank Group avec ProMyTrade, se fait par un lien d'affiliation, permettant aux nouveaux clients de bénéficier d’avantages considérables.</strong></Info><br></br>
                  Notre objectif est de combiner notre expertise locale avec les solutions globales de MultiBank Group pour créer une offre unique et compétitive.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo2} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo2 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Présentation de MultiBank Group</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Aperçu de MultiBank Group et de ses services
                  <br></br>
                  Avantages de trader avec MultiBank Group
                </h4>
                <p className={classes.description}>
                  MultiBank Group est un des leaders mondiaux dans le domaine des services financiers, offrant une gamme complète de produits incluant le forex, les métaux, les actions, les indices, les matières premières et les crypto-monnaies.
                  <br></br><Info><strong>Avec plus de 25 bureaux dans le monde et une régulation par 14 autorités financières, MultiBank Group garantit sécurité et fiabilité.</strong></Info>
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo4} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo4 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Services Financiers Proposés</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Description des services financiers qui vous sont offerts et avantages Clients
                </h4>
                <p className={classes.description}>
                  Nous proposons d’offrir les services suivants en partenariat avec MultiBank Group :
                  <ul>
                    <li>
                      Trading de forex et de métaux précieux
                    </li>
                    <li>
                      Investissement en actions et indices
                    </li>
                    <li>
                      Trading de matières premières et de crypto-monnaies
                    </li>
                  </ul>
                  Ces services permettront à nos clients de diversifier leurs portefeuilles et <Info><strong>de bénéficier des meilleures conditions de trading disponibles sur le marché.</strong></Info>
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={imagePartenariat} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + imagePartenariat + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Structure du Partenariat MultiBank Group / ProMyTrade</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Modalités du partenariat
                  <br></br>
                  Ouverture de comptes
                </h4>
                <p className={classes.description}>
                  <Info><strong>ProMyTrade vous permet d'ouvrir votre Compte chez MEX Atlantic qui est partie intégrante de MultiBank Group.</strong></Info><br></br>
                  Ce partenariat vous offre de nombreux avantages :<br></br><br></br>
                  Vous êtes investisseur ?
                  <br></br> <Info><strong>ProMyTrade met à votre disposition son robot de trading sur l'OR XAUUSD.</strong></Info><br></br>
                  <Info><strong>Celui-ci vous permet d'automatiser entièrement votre Trading.</strong></Info><br></br><br></br>
                  Vous êtes trader actif ? <br></br><Info><strong>Profitez de conditions exceptionnelles pour ouvrir un Compte ECN avec un dépôt minimum de 250 $ et des Spreads à partir de 0.0 pips.</strong></Info>
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo7} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo7 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Avantages du Partenariat</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Bénéfices pour nos clients
                </h4>
                <p className={classes.description}>
                  <Info><strong>Les plus :</strong></Info>
                  <ul>
                    <li>
                      Accès à des produits financiers diversifiés
                    </li>
                    <li>
                      Conditions de trading compétitives
                    </li>
                    <Info><li>
                      <strong>Mise à disposition d’un robot de trading lors de votre inscription via le lien d’Affiliation</strong>

                      <br></br>   <br></br>

                      <strong>Ou ouverture d'un Compte ECN aux conditions préférentielles via le lien ECN</strong>
                    </li> </Info>
                  </ul>
                </p>
                <h4 className={classes.cardTitle}>
                  Bénéfices pour MultiBank Group
                </h4>
                <p className={classes.description}>
                  <ul>
                    <li>
                      Expansion de la base de clients
                    </li>
                    <li>
                      Augmentation des volumes de trading
                    </li>
                  </ul>
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo5} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo5 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Plan de Promotion</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Stratégies de marketing et de promotion
                  <br></br>
                  Canaux de communication
                </h4>
                <p className={classes.description}>
                  Nous utiliserons les canaux suivants pour promouvoir les services :
                  <ul>
                    <li>
                      Campagnes de marketing digital
                    </li>
                    <li>
                      Réseaux sociaux et newsletters
                    </li>
                  </ul>
                  <Info><strong>Lien d'Affiliation (ci-dessous) pour obtenir les meilleurs avantages à l’ouverture de votre compte.</strong></Info>
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={combo6} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + combo6 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Conclusion</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Résumé des points clés
                  <br></br>
                  Engagement
                </h4>
                <p className={classes.description}>
                  Nous sommes convaincus que <Info><strong>ce partenariat apportera</strong></Info> des bénéfices significatifs à toutes les parties impliquées.
                  <br></br>
                  Nous attendons avec impatience de <Info><strong>collaborer avec Vous</strong></Info> pour que MultiBank Group puisse vous offrir des services financiers de premier ordre.
                  <br></br><Info><strong>Nous nous engageons </strong></Info> à promouvoir activement les services de MultiBank Group auprès de notre clientèle.<br></br>
                  MultiBank Group fournit <Info><strong>le support technique et les plateformes de trading</strong></Info> nécessaires en donnant l’accès au marché.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={imageQR} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + imageQR + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Vous êtes investisseur</h6>
                </Info>

                <h4 className={classes.cardTitle}>
                  Affiliation ProMyTrade
                  <br></br>
                  Ouvrez votre Compte GOLD en scannant le QR Code d’affiliation
                  <br></br>
                  ou cliquez ici  <Button href="https://mexatlantic.com/iframe/6625" target="_blank" color="primary" justIcon square> <ArrowForwardIcon className={classes.cardTitle} /></Button>
                  <br></br>
                  Puis envoyez un mail avec votre numéro de compte pour obtenir votre Robot de Trading  <Button href="redirect-mailto.php" target="_blank" color="primary" justIcon square> <ArrowForwardIcon className={classes.cardTitle} /></Button>
                </h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain blog>
              <CardHeader image plain>
                <img src={ProMyTradeMexAtlantic} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + ProMyTradeMexAtlantic + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <Info>
                  <h6>Vous êtes Trader Actif</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  Affiliation ProMyTrade
                  <br></br>
                  Ouvrez votre Compte ECN et profitez de cette offre unique
                  <br></br>
                  Cliquez ici  <Button href="https://mexatlantic.com/iframe/2716" target="_blank" color="primary" justIcon square> <ArrowForwardIcon className={classes.cardTitle} /></Button>
                </h4>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
