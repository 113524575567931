import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faXTwitter } from '@fortawesome/free-solid-svg-icons';
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// @material-ui/icons
//import ShoppingCart from "@material-ui/icons/ShoppingCart";
//import Store from "@material-ui/icons/Store";

//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { fas } from '@fortawesome/free-solid-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/presentationSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            md={8}
            sm={10}
            className={classNames(classes.mlAuto, classes.mrAuto)}
          >          
          {/*   <Button
              href="#"
              color={"white"}
              target="_blank"
              round
            >
              <Store className={classes.icons} /> Contactez nous par mail
            </Button> */}
          </GridItem>
          <div className={classes.socialLine}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem md={12}>
                  <h4 className={classes.title}>Suivez Nous ! </h4>
                   <Button color="github" href="https://x.com/multibankgroup" target="_blank" round>  <i className={"fa-brand fa-x-twitter " + classes.marginRight} />
                  <strong>X</strong>
                  </Button> 
                  <Button color="facebook" href="https://www.facebook.com/multibankgroup/"  target="_blank" round>
                    <i className={"fab fa-facebook " + classes.marginRight} />
                    Facebook
                  </Button>
                  <Button
              href="https://www.youtube.com/c/MultiBankGroup-Official"
              color={"youtube"}
              target="_blank"
              round
            >
              <i className="fab fa-youtube" /> Youtube
            </Button>
                  
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
